import { type FunctionComponent, useState } from 'react';
import { Popover } from '../../common-components/popover/popover.component';
import { HelpCircleIcon } from '../../svg/icons.component';

export const AutoAppliedFacetHelp: FunctionComponent = () => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<Popover
			popOverClassName="w5 f6"
			isVisible={isOpen}
			setIsVisible={setIsOpen}
			toggleElement={
				<div className="flex items-center pa1 pointer">
					<HelpCircleIcon className="theme-secondary" />
				</div>
			}>
			This filter has been automatically applied based on your search terms.
		</Popover>
	);
};
