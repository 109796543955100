import { type FunctionComponent } from 'react';
import { FACET_FILTER_HIDE_GROUP_NAME, RANGE_FACET_VALUE_SEPARATOR } from '../../../constants/search';
import { TrackedEvent } from '../../../helpers/analytics/event-types';
import { trackSearchEvent } from '../../../helpers/search-helper/search-analytics.helper';
import {
	facetGroupIsQuickShip,
	getFacetDisplayValue,
	getFacetGroupDisplayName,
	requestFacetsToSelectedFacetGroups,
	isRangeFacetGroup
} from '../../../helpers/search-helper/search-helper';
import { useSearchResults, type UseSearchResultsPayload } from '../../../hooks/apollo/search/search.hooks';
import { type FacetGroup, type FacetWithSource, type SelectedFacet, type SelectedFacetGroup } from '../../../types/search.types';
import { TextButton } from '../../buttons';
import { QuickShip } from '../../quick-ship/quick-ship.component';
import { CloseIcon } from '../../svg/icons.component';
import { AutoAppliedFacetHelp } from '../facet-help/facet-help.component';

function shouldShowGroupNameAsValue(group: SelectedFacetGroup, facet: SelectedFacet): boolean {
	return facet.value === 'true' && !facetGroupIsQuickShip(group);
}

function shouldHideGroupName(group: SelectedFacetGroup): boolean {
	return FACET_FILTER_HIDE_GROUP_NAME.has(group.name.toLowerCase());
}

/**
 * Return an array of indexes for selectedFacetGroups representing the traversal order for rendering.
 * Uses regular facet group list order. If a group is not found, sort it to the end, preserving original order.
 */
function getSelectedFacetRenderOrder(selectedFacetGroups: SelectedFacetGroup[], facetGroups: FacetGroup[]): number[] {
	const groupIndexes = facetGroups.reduce((result, group, ix) => {
		result[group.id] = ix;
		return result;
	}, {});
	return selectedFacetGroups
		.map((group, ix) => ({ group, ix }))
		.sort((a, b) => (groupIndexes[a.group.id] ?? a.ix + 1000) - (groupIndexes[b.group.id] ?? b.ix + 1000))
		.map(({ ix }) => ix);
}

const FacetFilterPill: FunctionComponent<{
	facet: SelectedFacet;
	group: SelectedFacetGroup;
	query: string;
	removeFacet: (facet: FacetWithSource) => void;
	createGTMEvent: UseSearchResultsPayload['createGTMEvent'];
}> = ({ facet, group, query, removeFacet, createGTMEvent }) => {
	const facetDisplayValue = getFacetDisplayValue(facet, group);

	const removeClick = () => {
		removeFacet({ id: facet.facetId, value: facet.value, sourceFacetId: facet.sourceFacetId });
		void trackSearchEvent(createGTMEvent(TrackedEvent.FACET_INTERACTION, { query, facet, group, applied: false }));
	};

	const isQuickShip = facetGroupIsQuickShip(group);

	let value = facetDisplayValue;
	let header = '';
	if (shouldShowGroupNameAsValue(group, facet)) {
		value = getFacetGroupDisplayName(group);
	} else if (!shouldHideGroupName(group)) {
		header = getFacetGroupDisplayName(group);
	}

	return (
		<div className="dib">
			<div className="ba items-center inline-flex pv2 pl3 pr2 f5 br-pill fade-in lh-title b--theme-grey-light">
				<>
					{header && <span className="ml1">{header}:</span>}
					<span className="ml1">{isQuickShip ? <QuickShip message={value} messageClasses={''} /> : value}</span>
				</>

				<div>
					{facet.autoApplied ? (
						<AutoAppliedFacetHelp />
					) : (
						<TextButton
							onClick={removeClick}
							color="grey-darker"
							ariaLabel={`Remove filter: ${group.name}: ${facetDisplayValue}`}>
							<div className="flex items-center pa2">
								<CloseIcon className="f5 theme-primary" />
							</div>
						</TextButton>
					)}
				</div>
			</div>
		</div>
	);
};

export type FacetFilterListProps = {
	useResults?: () => UseSearchResultsPayload;
	className?: string;
	hideClearAll?: boolean;
};

export const FacetFilterList: FunctionComponent<FacetFilterListProps> = ({
	useResults = useSearchResults,
	className = '',
	hideClearAll
}) => {
	const { query, results, previousResults, clearFacets, removeFacet, loading, createGTMEvent, request } = useResults();
	const renderResults = loading ? previousResults : results;
	const facetGroups = renderResults?.facetGroups ?? [];
	let selectedFacetGroups = renderResults?.selectedFacetGroups ?? [];

	if (loading) {
		selectedFacetGroups = requestFacetsToSelectedFacetGroups(request.facetFilter, facetGroups) ?? selectedFacetGroups;
	}

	const hasRemovableFacets = selectedFacetGroups.some((g) => g.facets.some((f) => !f.autoApplied));
	const clearAllFacets = () => {
		selectedFacetGroups.forEach((group) => {
			group.facets
				.filter((facet) => !facet.autoApplied)
				.forEach((facet) => {
					void trackSearchEvent(createGTMEvent(TrackedEvent.FACET_INTERACTION, { query, facet, group, applied: false }));
				});
		});
		clearFacets();
	};

	// Render selected facet groups ordered the same as facet groups.
	const groupSortOrder = getSelectedFacetRenderOrder(selectedFacetGroups, facetGroups);

	return selectedFacetGroups.length ? (
		<div className={`flex flex-wrap ga2 ${className}`}>
			{!hideClearAll && hasRemovableFacets && (
				<TextButton automationHook="clear-all-facets" onClick={clearAllFacets} className="ph1 pv3 mr1">
					Clear All
				</TextButton>
			)}
			{groupSortOrder.map((groupIndex) => {
				const group = selectedFacetGroups[groupIndex];
				const isRangeGroup = isRangeFacetGroup(group);
				let rangeFacet, rangeMin, rangeMax;

				if (isRangeGroup) {
					rangeMin = group.range?.min;
					rangeMax = group.range?.max;
					rangeFacet = {
						facetId: group.facets[0].facetId,
						value: `${rangeMin}${RANGE_FACET_VALUE_SEPARATOR}${rangeMax}`,
						autoApplied: group.facets[0].autoApplied
					};
				}

				const selectedFacets = !isRangeGroup ? group.facets : [];

				// Render facets ordered by value.
				const facetOrder = selectedFacets
					.map((facet, ix) => ({ facet, ix }))
					.sort((a, b) => a.facet.value.localeCompare(b.facet.value))
					.map(({ ix }) => ix);

				return isRangeGroup ? (
					<FacetFilterPill
						key={`${group.id}:${rangeMin}-${rangeMax}`}
						facet={rangeFacet}
						group={group}
						query={query}
						removeFacet={removeFacet}
						createGTMEvent={createGTMEvent}
					/>
				) : (
					facetOrder.map((facetIndex) => {
						const facet = selectedFacets[facetIndex];
						return (
							<FacetFilterPill
								key={`${facet.facetId}:${facet.value}`}
								facet={facet}
								group={group}
								query={query}
								removeFacet={removeFacet}
								createGTMEvent={createGTMEvent}
							/>
						);
					})
				);
			})}
		</div>
	) : null;
};
