import { type FunctionComponent } from 'react';
import { FEATURE_FLAGS } from '../../../constants/general';
import { formatNumber, pluralize } from '../../../helpers/general-helper/general-helper';
import {
	type UseFacetGroupResults,
	type UseSearchResultsPayload,
	useFacetGroupResults,
	useSearchResults
} from '../../../hooks/apollo/search/search.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { StyledButton } from '../../buttons';
import { IconButton } from '../../buttons/icon-button/icon-button.component';
import { Drawer } from '../../common-components/drawer/drawer.component';
import { CloseIcon } from '../../svg/icons.component';
import { FacetFilterList } from '../facet-filter-list/facet-filter-list.component';
import { FacetList } from '../facet-list/facet-list.component';
import { FacetDrawerTitle } from './facet-drawer-title/facet-drawer-title.component';
import { drawer, facetDrawerFilters, facetDrawerList } from './facet-drawer.css';

export type FacetDrawerProps = {
	onFacetDrawerClose: () => void;
	useResults?: () => UseSearchResultsPayload;
	useSearchFacetGroupResults?: (facetGroupId: string) => UseFacetGroupResults;
};

export const FacetDrawer: FunctionComponent<FacetDrawerProps> = ({
	onFacetDrawerClose,
	useResults = useSearchResults,
	useSearchFacetGroupResults = useFacetGroupResults
}) => {
	const { results, previousResults, loading, clearFacets, request } = useResults();
	const renderResults = results ?? previousResults;
	const resultCount = renderResults?.count ?? 0;
	const formattedCount = formatNumber(resultCount);
	const isSortInsideFacetDrawer = useFeature(FEATURE_FLAGS.SORT_INSIDE_FACET_DRAWER);
	const hasFilters = Boolean(renderResults?.selectedFacetGroups.length) || (loading && request.facetFilter.length > 0);

	const customCloseButton = (
		<IconButton
			ariaLabel="close filters"
			onClick={onFacetDrawerClose}
			buttonStyle="ICON"
			size="ICON"
			className="bn absolute top-0 right-0 w-auto mt2 mr2 pv3">
			<CloseIcon className="f3 db" />
		</IconButton>
	);

	return (
		<Drawer
			ariaLabel="Filter Search Results"
			onClose={onFacetDrawerClose}
			direction="right"
			className={`pa0 z-9999 ${drawer}`}
			classContainerContent="flex flex-column h-100"
			customCloseButton={customCloseButton}>
			<FacetDrawerTitle
				title="Filters"
				hasFilters={hasFilters}
				clearFacets={clearFacets}
				loading={loading}
				titleClassName="pv3 f4 fw6"
				containerClassName="flex items-center pv2 pl4 pr6 bb b--theme-grey-light lh-solid"
			/>
			<div className={`flex-grow-1 pb2 overflow-y-auto ${facetDrawerList}`}>
				<FacetList
					useResults={useResults}
					useSearchFacetGroupResults={useSearchFacetGroupResults}
					hideDesktopHeader={true}
					includeSortBy={isSortInsideFacetDrawer}
				/>
			</div>
			<div className="w-100 pb4 pt2 bg-theme-white bt b--theme-grey-light">
				<FacetFilterList
					useResults={useSearchResults}
					hideClearAll={true}
					className={`flex-nowrap overflow-x-auto overflow-y-hidden nowrap ph4 pv3 mb2 ${facetDrawerFilters}`}
				/>
				<div className={`ph4 center tc w-100 ${hasFilters ? '' : 'mt3'}`}>
					<StyledButton buttonStyle="PRIMARY" onClick={onFacetDrawerClose}>
						View {formattedCount} {pluralize('Result', Number(resultCount))}
					</StyledButton>
				</div>
			</div>
		</Drawer>
	);
};
