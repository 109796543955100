import { type FunctionComponent } from 'react';
import { TextButton } from '../../../buttons';
import { Loading } from '../../../common-components/loading/loading.component';
import { TuneIcon } from '../../../svg/icons.component';

export type FacetDrawerTitleProps = {
	title: string;
	containerClassName?: string;
	titleClassName?: string;
	hasFilters?: boolean;
	clearFacets?: () => void;
	loading?: boolean;
};

export const FacetDrawerTitle: FunctionComponent<FacetDrawerTitleProps> = ({
	title,
	containerClassName = '',
	titleClassName = '',
	hasFilters = false,
	clearFacets,
	loading = false
}) => {
	return (
		<>
			<div className={containerClassName}>
				<div className={`flex items-center ${titleClassName}`}>
					<TuneIcon />
					<span className="ml2">{title}</span>
				</div>
				{hasFilters && (
					<TextButton onClick={clearFacets} underline={false} className="ml3 ph2 pv3 f5 fw4 lh-title theme-primary-dark fade-in">
						Clear All
					</TextButton>
				)}
				{loading && (
					<div className="ml3 f3 self-center">
						<Loading />
					</div>
				)}
			</div>
		</>
	);
};
