import { type FunctionComponent } from 'react';

export type CornerRibbonProps = {
	text: string;
};

export const CornerRibbon: FunctionComponent<CornerRibbonProps> = ({ text }) => (
	<div style={{ left: '-84px', top: '33px' }} className="fixed rotate-315 f7 pa1 w5 tc z-999 bg-theme-emphasis theme-white">
		{text}
	</div>
);
